<template>
  <component :is="'b-card'">
    <b-row>
      <b-col
        cols="12"
      >
        <div>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >

            <b-form
              ref="form"
              class="repeater-form"
              :style="{height: trHeight} "
              z-index="999"
            >
              <b-row>
                <!-- Field: Name -->
                <b-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Name')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Name')"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="name"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Description -->
                <b-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group
                    :label="$t('Description')"
                    label-for="description"
                  >
                    <b-form-input
                      id="registrationNumber"
                      v-model="description"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group
                    :label="$t('Enabled')"
                    label-for="profileEnabled"
                  >
                    <b-form-checkbox
                      id="profileEnabled"
                      v-model="enabled"
                      name="check-button"
                      switch
                      inline
                    />

                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
                <b-col cols="12">
                  <h6>{{ $t('CPV criteria') }}:</h6>
                </b-col>
              </b-row>

              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in items"
                :key="index"
                ref="row"
              >
                <!-- Item CPV -->
                <b-col md="3">
                  <b-form-group
                    :label="$t('CPV')"
                    label-for="cpv"
                  >
                    <treeselect
                      v-model="item.selected_cpvs"
                      :multiple="true"
                      :options="cpvsOptions"
                      :load-options="loadOptions"
                      :auto-load-root-options="true"
                      :normalizer="normalizeCpvs"
                      :disable-fuzzy-matching="true"
                    />

                  </b-form-group>
                </b-col>

                <!-- Item Search criteria -->
                <b-col md="4">
                  <b-form-group
                    :label="$t('Search criteria')"
                    label-for="searchCriteria"
                  >
                    <b-form-input
                      id="searchCriteria"
                      :key="item.id"
                      v-model="item.search_query"
                      type="text"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>

                <!-- Company -->
                <b-col md="2">
                  <b-form-group
                    :label="$t('Company')"
                    label-for="company"
                  >
                    <b-form-input
                      id="company"
                      :key="item.id"
                      v-model="item.company"
                      type="text"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col
                  md="2"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>{{ $t('Delete') }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    @click="repeatAgain"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>{{ $t('Add CPV Criteria') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="onSubmit"
              >
                {{ $t('Save') }}
              </b-button>

            </b-col>
          </b-row>
          </validation-observer>
        </div>
      </b-col>
    </b-row>
  </component>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BForm, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import Treeselect, { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    Treeselect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      name: null,
      description: null,
      enabled: false,
      items: [{
        selectedCpvs: [],
      }],
      cpvsOptions: null,
      normalizeCpvs(node) {
        return {
          id: node.code,
          label: `${node.code} - ${node.default_title}`,
          children: node.children,
        }
      },
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    fetchCpvs() {
      this.$store.dispatch('cpvs/fetchCpvs')
        .then(response => {
          this.cpvsOptions = response.data
        })
    },
    repeatAgain() {
      this.items.push({
        selected_cpvs: [],
        search_query: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    async loadOptions({ action }) {
      if (action === LOAD_ROOT_OPTIONS && !this.cpvsOptions) {
        this.fetchCpvs()
      }
    },
    onSubmit() {
      const payload = {
        name: this.name,
        description: this.description,
        filters: this.items,
        is_active: this.enabled,
      }

      store.dispatch('tenders/addProfile', payload)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile created',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
